import * as React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../../components/layout'

const Book = ({ data }) => {
    const book = data.book

    return (
        <Layout>
            <h1>{book?.title ?? `Book Title`}</h1>
        </Layout>
    )
}

export default Book

export const query = graphql`
    query BookQuery($id: String!) {
        book: sanityBook(id: { eq: $id }) {
            title
            slug {
                current
            }
        }
    }
`